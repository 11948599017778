/* eslint-disable @typescript-eslint/no-explicit-any */

import fetch from 'isomorphic-fetch';
// import _includes from 'lodash/includes';

const getAppPort = (app: string) => {
  if (process.env.NODE_ENV === 'development') {
    switch (app) {
      case 'NAV':
        return 3005;
      case 'REGISTRY':
        return 3006;
      case 'PREAUTH':
        return 3009;
      case 'MARKETPLACE':
        return 3008;
      case 'WEDDING':
      case 'WEB_WEDDING':
      default:
        return 3003;
    }
  }

  switch (app) {
    case 'NAV':
      return 9005;
    case 'REGISTRY':
      return 9006;
    case 'PREAUTH':
      return 9009;
    case 'MARKETPLACE':
      return 9008;
    case 'WEDDING':
    case 'WEB_WEDDING':
    default:
      return 9003;
  }
};

export const getServerURL = (path: string, app = 'WEB_WEDDING') => {
  if (typeof window === 'undefined' && !/^http/.test(path)) {
    const baseURL = process.env.WEB_HOST;
    const port = getAppPort(app);
    return `${baseURL}:${port}${path}`;
  }

  return path;
};

const Method = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

const handleErrors = (response: Response, errorPropName: 'statusText' = 'statusText') => {
  if (!response.ok) {
    const error = new Error(response[errorPropName]);
    (error as Error & { response: Response }).response = response;
    throw error;
  }
  return response;
};

// new handleErrors
// const handleErrors = ([ok, response]) => {
//   console.log('handle errors');
//   if (!ok) {
//     const errorMessage = (response.error && response.error.message) || 'An error has occurred';
//     const error = new Error(errorMessage);
//     error.response = response;
//     throw error;
//   }
//   return response;
// };

// const handlePromise = (response) => {
//   console.log('handle promise');
//   const contentType = response.headers && response.headers.get('Content-Type');
//   if (_includes(contentType, 'application/json')) {
//     return Promise.all([response.ok, response.json()]);
//   }
//   return Promise.all([response.ok, response.text()]);
// };

// new request
// const request = (url, options) => (
//   fetch(url, options)
//     .then(handlePromise)
//     .then(handleErrors)
// );

// old request
const request = (url: string, options?: RequestInit, returnType?: string) => {
  const finalURL = getServerURL(url);
  return fetch(finalURL, options)
    .then(handleErrors)
    .then(response => {
      if (returnType === 'text') {
        return response.text();
      }
      // TODO: look into adding check for response.headers.get('content-type')
      // better to check if contentType.includes('application/json') rather than
      // getting Unexpected end of json error
      return response.json();
    });
};

const get = <ResponseType = any>(
  url: string,
  options: RequestInit = {},
  headers: HeadersInit = {},
  returnType = 'json'
): Promise<ResponseType> => {
  const finalHeaders = { 'Content-Type': 'application/json', ...headers };
  const finalOptions: RequestInit = {
    method: Method.get,
    credentials: 'same-origin',
    headers: finalHeaders,
    ...options,
  };

  return request(url, finalOptions, returnType);
};

const post = <ResponseType = any, BodyType = Record<string, unknown>>(
  url: string,
  body: BodyType = {} as BodyType,
  options: RequestInit = {},
  headers: HeadersInit = {},
  returnType = 'json'
): Promise<ResponseType> => {
  const finalHeaders = { 'Content-Type': 'application/json', ...headers };
  const finalBody = JSON.stringify(body);
  const finalOptions: RequestInit = {
    method: Method.post,
    credentials: 'same-origin',
    body: finalBody,
    headers: finalHeaders,
    ...options,
  };

  return request(url, finalOptions, returnType);
};

const put = <ResponseType = any, BodyType = Record<string, unknown>>(
  url: string,
  body: BodyType = {} as BodyType,
  options: RequestInit = {},
  headers: HeadersInit = {},
  returnType = 'json'
): Promise<ResponseType> => {
  const finalHeaders = { 'Content-Type': 'application/json', ...headers };
  const finalBody = JSON.stringify(body);
  const finalOptions: RequestInit = {
    method: Method.put,
    credentials: 'same-origin',
    body: finalBody,
    headers: finalHeaders,
    ...options,
  };

  return request(url, finalOptions, returnType);
};

const deleteMethod = <ResponseType = any>(
  url: string,
  body = {},
  options: RequestInit = {},
  headers: HeadersInit = {},
  returnType = 'json'
): Promise<ResponseType> => {
  const finalHeaders = { 'Content-Type': 'application/json', ...headers };
  const finalBody = JSON.stringify(body);
  const finalOptions: RequestInit = {
    method: Method.delete,
    credentials: 'same-origin',
    body: finalBody,
    headers: finalHeaders,
    ...options,
  };
  return request(url, finalOptions, returnType);
};

const ApiService = {
  request,
  get,
  post,
  put,
  delete: deleteMethod,
  handleErrors,
};

export default ApiService;
